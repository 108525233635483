import { NextPageContext } from 'next';
import { fetchProducts } from '../axios/fetchers';
import { ProductType } from '../util/types';
import { productList } from '../mockdata';
import getUserGeo from '../util/getUserGeo';
import ProductCard from '../components/ProductCard/ProductCard';
import Container from '../components/Container/Container';
import CardsGrid from '../components/CardsGrid/CardsGrid';
import Title from '../components/Title/Title';

export default function Home({
  data,
  locale,
  localeCountry,
}: {
  data: ProductType[];
  locale: string;
  localeCountry?: string;
}) {
  return (
    <Container>
      <Title order={1}>{productList.title}</Title>
      <CardsGrid>
        {data?.map((item, index) => (
          <ProductCard
            key={index}
            {...item}
            href={`/product/${item.id}?${
              localeCountry ? `locale_country=${localeCountry}` : `locale=${locale}`
            }`}
          />
        ))}
      </CardsGrid>
    </Container>
  );
}

export async function getServerSideProps(context: NextPageContext) {
  let localeCountry: string[] = [];
  if (context.query?.locale_country) {
    localeCountry = (context.query?.locale_country as string).split('-');
  }

  const geo = await getUserGeo(context.req, context.res, context.query.locale as string).then(
    (res) => res
  );
  const data = await fetchProducts({ target_country: localeCountry?.[1] || geo.country }).then(
    (res) => res
  );

  return {
    props: {
      data,
      locale: localeCountry[0] || geo.locale,
      localeCountry: localeCountry.join('-') || null,
    },
  };
}
