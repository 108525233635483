import React from 'react';
import cx from 'clsx';
import Text from '../Text/Text';
import classes from './Box.module.scss';

export default function Box({
  className,
  children,
  animated,
  ...others
}: React.HTMLProps<HTMLDivElement> & { animated?: boolean }) {
  return (
    <div className={cx(classes.box, { [classes.animated]: animated }, className)} {...others}>
      {typeof children === 'string' ? <Text>{children}</Text> : children}
    </div>
  );
}
