import React from 'react';
import cx from 'clsx';
import Image from 'next/future/image';
import { ProductType } from '../../util/types';
import Anchor from '../Anchor/Anchor';
import Text from '../Text/Text';
import Box from '../Box/Box';
import classes from './ProductCard.module.scss';

export interface ProductCardProps extends React.HTMLProps<HTMLAnchorElement> {
  href: string;
  name?: string;
  thumbnail?: string;
  asHref?: string;
}

export default function ProductCard({
  className,
  href,
  asHref,
  name,
  thumbnail,
  ...others
}: ProductCardProps & ProductType) {
  return (
    <Anchor href={href} {...others} target={undefined} as={asHref || href}>
      <Box className={cx(classes.box, className)}>
        {thumbnail ? (
          <Image
            src={thumbnail}
            className={classes.image}
            width="300"
            height="300"
            alt=""
            unoptimized
          />
        ) : (
          <div className={classes.image} />
        )}
        <Text weight="bold" align="center" className={classes.title}>
          {name}
        </Text>
      </Box>
    </Anchor>
  );
}
