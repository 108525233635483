import React from 'react';
import cx from 'clsx';
import Text, { TextProps } from '../Text/Text';
import classes from './Title.module.scss';

export interface TitleProps<T> extends TextProps<T> {
  order?: 1 | 2 | 3 | 4 | 5 | 6;
}

export default function Title({
  className,
  component,
  order = 3,
  children,
  ...others
}: TitleProps<HTMLTitleElement>) {
  return (
    <Text
      component={component || `h${order}`}
      className={cx(classes.title, [classes[`h${order}`]], className)}
      {...others}
    >
      {children}
    </Text>
  );
}
