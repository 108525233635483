import React from 'react';
import cx from 'clsx';
import classes from './CardsGrid.module.scss';

export default function CardsGrid({
  className,
  children,
  ...others
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className={cx(classes.grid, className)} {...others}>
      {children}
    </div>
  );
}
